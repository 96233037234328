import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import PageContext from '../types/PageContext'
import DefaultPageTemplate from './DefaultPageTemplate'

const RootPageTemplate: React.FunctionComponent<RootPageTemplateProps> = (props: RootPageTemplateProps) => {
    const { pageContext } = props

    useEffect(() => {
        if (pageContext.isRoot && (navigator.language === 'de' || navigator.language === 'fr')) {
            navigate(`/${navigator.language}/`, { replace: true })
        }
    })

    return DefaultPageTemplate(props)
}

export default RootPageTemplate

interface RootPageTemplateProps {
    pageContext: PageContext<any>
}
